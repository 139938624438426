var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"tp-table-scroll tp-table__row-pointer datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.removeAccessStatusRequest.value === 'loading-getRemoveAccesses',"loading-text":"Đang tải dữ liệu","items":_vm.removeAccesses,"item-key":"id"},on:{"click:row":function($event){return _vm.viewDetail($event)}},scopedSlots:_vm._u([{key:"item.new_option_sku",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.new_option_sku)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.new_option_sku)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])]}},{key:"item.old_option_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-wrap my-1"},[_vm._v(_vm._s(_vm.getSpecsText(item.old_option_specs)))])]}},{key:"item.new_option_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"p-wrap my-1"},[_vm._v(_vm._s(_vm.getSpecsText(item.new_option_specs)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.status === 1
          ? 'green'
          : item.status === -1
          ? 'red accent-2'
          : 'orange',"small":"","outlined":""}},[_vm._v(" "+_vm._s(item.status === 1 ? "Thành công" : item.status === -1 ? "Bị huỷ" : "Bị huỷ")+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }
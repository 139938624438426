<template>
  <div class="d_flex">
    <!-- Start: Filter area -->
    <div class="px-5">
      <filter-area
        :statusFilter="statusFilter"
        :timeFilter="timeFilter"
        @updateStatusFilter="updateStatusFilter($event)"
        @updateTimeFilter="updateTimeFilter($event)"
      ></filter-area>
    </div>
    <!-- End: Filter area -->
    <!-- Start: Main area -->
    <div class="flex-grow-1 overflow-hidden">
      <!-- Start: Top actions -->
      <top-action
        :current-page="currentPage"
        :selectedRemoveAccesses="selectedRemoveAccesses"
        :selected-show-filter-id="itemPerPage"
        :search-key="searchKey"
        @exportReportFile="exportReportFile"
        @updateCurrentPage="updateCurrentPage($event)"
        @updateItemPerPage="updateItemPerPage($event)"
        @updateSearchKey="updateSearchKey($event)"
        @updateSelectedRemoveAccesses="selectedRemoveAccesses = $event"
      ></top-action>
      <!-- End: Top actions -->
      <!-- Start: Data table -->
      <div class="mr-5">
        <data-table-items
          :selectedRemoveAccesses="selectedRemoveAccesses"
          @updateSelectedRemoveAccesses="selectedRemoveAccesses = $event"
        ></data-table-items>
      </div>
      <!-- End: Data table -->
    </div>
    <!-- End: Main area -->
    <!-- Start: Modal 3rd store -->
    <!-- End: Modal 3rd store -->
  </div>
</template>

<script>
import DataTableItems from "./components/DataTableItems";
import FilterArea from "./components/FilterArea";
import TopAction from "./components/TopAction";
import { exportExcelFile } from "@/core/composables";

export default {
  components: {
    DataTableItems,
    FilterArea,
    TopAction
  },
  data() {
    return {
      currentPage: 1,
      itemPerPage: 50,
      selectedRemoveAccesses: [],
      searchKey: null,
      statusFilter: null,
      timeFilter: {}
    };
  },
  watch: {
    "$route.query"(val) {
      this.getRemoveAccessesFromRouteQuery(val);
    }
  },
  async created() {
    const route = this.$route;

    this.getRemoveAccessesFromRouteQuery(route.query);
  },
  methods: {
    async exportReportFile() {
      const payload = {
        status:
          this.statusFilter && this.statusFilter !== "all"
            ? [this.statusFilter]
            : null,
        fromDate: this.timeFilter.value ? this.timeFilter.value[0] : null,
        toDate: this.timeFilter.value ? this.timeFilter.value[1] : null,
        search: this.searchKey
      };

      await exportExcelFile(this, {
        storeName: "REMOVE_ACCESS",
        payload,
        fileName: "bao-cao-thao-linh-kien",
        isCheckExportTime: true
      });
    },

    pushRouter() {
      this.$router.push({
        name: "goods_removing-accessories",
        query: {
          status:
            this.statusFilter && this.statusFilter !== "all"
              ? this.statusFilter
              : undefined,
          search:
            this.searchKey && this.searchKey.length > 0
              ? this.searchKey
              : undefined,
          fromDate: this.timeFilter.value
            ? this.timeFilter.value[0]
            : undefined,
          toDate: this.timeFilter.value ? this.timeFilter.value[1] : undefined,
          type: this.timeFilter.type ? this.timeFilter.type : undefined,
          limit: this.itemPerPage,
          page: this.currentPage
        }
      });
    },

    getRemoveAccessesFromRouteQuery(query) {
      if (query.page) {
        // Set current page
        this.currentPage = parseInt(query.page);
        // Set item per page
        this.itemPerPage = parseInt(query.limit);
        // Set time filter
        this.timeFilter =
          query.fromDate && query.toDate
            ? {
                value: [query.fromDate, query.toDate],
                type: parseInt(query.type)
              }
            : { fromDate: null, toDate: null };
        // Set status filter
        this.statusFilter = query.status ? parseInt(query.status) : "all";
        // Set search key
        this.searchKey = query.search || null;

        // Get products
        this.$store.dispatch("REMOVE_ACCESS/getRemoveAccesses", {
          filter: {
            status:
              this.statusFilter && this.statusFilter !== "all"
                ? [this.statusFilter]
                : null,
            fromDate: this.timeFilter.value ? this.timeFilter.value[0] : null,
            toDate: this.timeFilter.value ? this.timeFilter.value[1] : null
          },
          search: this.searchKey,
          per_page: this.itemPerPage,
          cur_page: this.currentPage
        });
      } else {
        // Push router query
        this.pushRouter();
      }
    },

    updateCurrentPage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateTimeFilter(val) {
      this.timeFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateItemPerPage(num) {
      if (this.itemPerPage === num) return;
      this.itemPerPage = num;
      this.currentPage = 1;
      this.selectedProductOptions = [];
      // Push router query
      this.pushRouter();
    },

    updateStatusFilter(val) {
      this.statusFilter = val;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    },

    updateSearchKey(val) {
      this.searchKey = val.searchKey;
      this.currentPage = 1;
      // Push router query
      this.pushRouter();
    }
  }
};
</script>

<style lang="scss" scoped></style>
